// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig:  {
    apiKey: "AIzaSyDrSNrMEgu3Szz55JiV_6j1gfUdFpUVFxE",
    authDomain: "aendyapp.firebaseapp.com",
    databaseURL: "https://aendyapp.firebaseio.com",
    projectId: "aendyapp",
    storageBucket: "aendyapp.appspot.com",
    messagingSenderId: "222340217626",
    appId: "1:222340217626:web:b5c52f1f50a67aa88c4aa3"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
