import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

import { RoleGuard } from './guards/role.guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
 
 
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {path: 'seller',
/*canActivate: [AngularFireAuthGuard, RoleGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      role: 'SELLER' 
    },*/

children: [
  
  
  {
    path: 'list',
   
    loadChildren: () => import('./pages/seller-list/seller-list.module').then( m => m.SellerListPageModule)
  },
  {
    path: 'list/:id',
    loadChildren: () => import('./pages/seller-edit/seller-edit.module').then( m => m.SellerEditPageModule)
  },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  }
]
},
  {
    path: 'orders',
    loadChildren: () => import('./pages/buyer-orders/buyer-orders.module').then( m => m.BuyerOrdersPageModule)
  },
  {
    path: 'contents',
    loadChildren: () => import('./pages/contents/contents.module').then( m => m.ContentsPageModule)
  },
  {
    path: 'content-detail',
    loadChildren: () => import('./pages/content-detail/content-detail.module').then( m => m.ContentDetailPageModule)
  },
  {
    path: 'rotator',
    loadChildren: () => import('./pages/rotator/rotator.module').then( m => m.RotatorPageModule)
  },
  {
    path: 'seller-list-details',
    loadChildren: () => import('./seller-list-details/seller-list-details.module').then( m => m.SellerListDetailsPageModule)
  },
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
